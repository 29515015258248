import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as adminRouting from '@app/admin/constants/routing.constants';
import { AdminOrRealEstateAgentGuard } from '@app/authentication/guards/admin-or-real-estate-agent/admin-or-real-estate-agent.guard';
import { AdminGuard } from '@app/authentication/guards/admin/admin.guard';
import { NoRealEstateAgentGuard } from '@app/authentication/guards/no-real-estate-agent/no-real-estate-agent.guard';
import { ExternalInfluencerConfigureAboutComponent } from '@app/external-influencer/components/external-influencer-configure-about/external-influencer-configure-about.component';
import { ExternalInfluencerConfigureContactComponent } from '@app/external-influencer/components/external-influencer-configure-contact/external-influencer-configure-contact.component';
import { ExternalInfluencerConfigureHeaderComponent } from '@app/external-influencer/components/external-influencer-configure-header/external-influencer-configure-header.component';
import { ExternalInfluencerConfigureLocationsComponent } from '@app/external-influencer/components/external-influencer-configure-locations/external-influencer-configure-locations.component';
import { modalRouterOutlet } from '@app/external-influencer/constants/routing.constants';
import { AppFeatureScopeGuard } from '@app/feature-scope/guards/app-feature-scope-guard.service';
import { mortgageBaseRoute, mortgageBreadcrumb } from '@app/mortgage/constants/routing.constants';
import { otsBaseRoute } from '@app/ots/constants/ots.constants';
import * as partnerRouting from '@app/partner/constants/routing.constants';
import { AppPreLoadingStrategy } from './app-pre-loading.strategy';
import { AuthenticationGuard } from './guards/authentication/authentication.guard';
import { FeatureScope, LogicalOperation, NavigationData } from '@smooved/core';
import { Navigation } from './navigation/enums/navigation.enum';
import { FeatureScopeForReferrerGuard } from '@app/feature-scope/guards/feature-scope-for-referrer.guard';
import { MainContainerTemplateComponent } from '@app/main-container-template/main-container-template.component';
import { RedirectComponent } from '@app/redirect/components/redirect.component';
import { DashboardV2AllowedRolesGuard } from '@app/transaction/guards/dashboard-v2-allowed-roles.guard';
import { uiV2 } from '@app/app.constants';

const routes: Routes = [
    {
        path: 'companion',
        loadChildren: () =>
            import('./../../projects/smooved-companion/src/lib/smooved-companion.module').then((m) => m.SmoovedCompanionModule),
    },

    {
        path: '',
        loadComponent: () => import('@app/transaction/containers/skeleton/skeleton.container').then((m) => m.SkeletonContainer),
        canActivate: [DashboardV2AllowedRolesGuard()],
        children: [
            {
                path: '',
                loadComponent: () => import('@app/transaction/containers/dashboard/dashboard.container').then((m) => m.DashboardContainer),
            },
            {
                path: 'energy-final-invoice',
                loadComponent: () =>
                    import('@app/transaction/containers/energy-final-invoice/energy-final-invoice.container').then(
                        (m) => m.EnergyFinalInvoiceContainer
                    ),
            },
        ],
        data: {
            styleVersion: uiV2,
        },
    },
    {
        path: '',
        component: MainContainerTemplateComponent,
        /** TODO: Enable when set LeaverDashboard feature flag */
        // canActivate: [RedirectToDashboardV2Guard()],
        children: [
            {
                path: Navigation.Start,
                loadChildren: () => import('./start/start.module').then((m) => m.StartModule),
            },
            {
                path: Navigation.Moves,
                loadChildren: () => import('./move/move.module').then((m) => m.MoveModule),
            },
            {
                path: Navigation.Authentication,
                loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
            },
            {
                path: adminRouting.adminBaseRoute,
                canActivate: [AuthenticationGuard],
                canLoad: [AdminGuard],
                loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
            },
            {
                path: Navigation.Surveys,
                canActivate: [AuthenticationGuard],
                loadChildren: () => import('./surveys/surveys.module').then((m) => m.SurveysModule),
            },
            {
                path: Navigation.RealEstateAgent,
                canLoad: [AdminOrRealEstateAgentGuard],
                loadChildren: () => import('./real-estate-agent/real-estate-agent.module').then((m) => m.RealEstateAgentModule),
            },
            {
                path: Navigation.Dashboard,
                canLoad: [NoRealEstateAgentGuard],
                loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
            },
            {
                path: otsBaseRoute,
                canActivate: [AdminOrRealEstateAgentGuard, AppFeatureScopeGuard],
                loadChildren: () => import('./ots/ots.module').then((m) => m.OtsModule),
                data: {
                    preload: true,
                    [NavigationData.FeatureScopeConfig]: {
                        scopes: [FeatureScope.Transactions],
                        operator: LogicalOperation.And,
                    },
                },
            },
            {
                path: mortgageBaseRoute,
                loadChildren: () => import('./mortgage/mortgage.module').then((m) => m.MortgageModule),
                data: {
                    [NavigationData.Breadcrumb]: mortgageBreadcrumb,
                },
            },
            {
                path: Navigation.EnergyOptimization,
                canActivate: [FeatureScopeForReferrerGuard],
                data: {
                    [NavigationData.CoBranding]: true,
                    [NavigationData.FeatureScopeConfig]: {
                        scopes: [FeatureScope.Transactions],
                        operator: LogicalOperation.And,
                    },
                },
                loadChildren: () => import('./energy-optimization/energy-optimization.module').then((m) => m.EnergyOptimizationModule),
            },
            {
                path: Navigation.EnergyContract,
                data: {
                    [NavigationData.CoBranding]: true,
                },
                loadChildren: () => import('./energy-contract/energy-contract.module').then((m) => m.EnergyContractModule),
            },
            {
                path: Navigation.Whise,
                data: {
                    [NavigationData.CoBranding]: true,
                },
                loadChildren: () => import('./whise/whise.module').then((m) => m.WhiseModule),
            },
            {
                path: Navigation.Client,
                loadChildren: () => import('./client/client.module').then((m) => m.ClientModule),
            },
            {
                path: Navigation.Telecom,
                canLoad: [NoRealEstateAgentGuard],
                loadChildren: () => import('./telecom/telecom.module').then((m) => m.TelecomModule),
            },
            {
                path: Navigation.TelecomTransfer,
                canLoad: [NoRealEstateAgentGuard],
                loadChildren: () => import('./telecom-transfer/telecom-transfer.module').then((m) => m.TelecomTransferModule),
            },
            {
                path: Navigation.Energy,
                canLoad: [NoRealEstateAgentGuard],
                loadChildren: () => import('./energy/energy.module').then((m) => m.EnergyModule),
            },
            {
                path: Navigation.EnergyTransfer,
                canLoad: [NoRealEstateAgentGuard],
                loadChildren: () => import('./energy-transfer/energy-transfer.module').then((m) => m.EnergyTransferModule),
            },
            {
                path: Navigation.Contract,
                loadChildren: () => import('./contract/contract.module').then((m) => m.ContractModule),
            },
            {
                path: Navigation.Insurances,
                canLoad: [NoRealEstateAgentGuard],
                loadChildren: () => import('./insurances/insurances.module').then((m) => m.InsurancesModule),
            },
            {
                path: Navigation.Logistics,
                canLoad: [NoRealEstateAgentGuard],
                loadChildren: () => import('./logistics/logistics.module').then((m) => m.LogisticsModule),
            },
            {
                path: Navigation.Post,
                canLoad: [NoRealEstateAgentGuard],
                loadChildren: () => import('./post/post.module').then((m) => m.PostModule),
            },
            {
                path: Navigation.Water,
                loadChildren: () => import('./water/water.module').then((m) => m.WaterModule),
            },
            {
                path: Navigation.Complete,
                loadChildren: () => import('./complete/complete.module').then((m) => m.CompleteModule),
            },
            {
                path: partnerRouting.baseRoute,
                loadChildren: () => import('./partner/partner.module').then((m) => m.PartnerModule),
            },
            {
                path: 'ui-showcase',
                canLoad: [AdminGuard],
                loadChildren: () => import('./ui/showcase/showcase.module').then((m) => m.UiShowCaseModule),
            },
            {
                path: 'leaderboard',
                canLoad: [AdminGuard],
                loadChildren: () => import('./leaderboard/leaderboard.module').then((m) => m.LeaderboardModule),
            },
            {
                path: 'services/boiler-maintenance',
                loadChildren: () =>
                    import('./services/boiler-maintenance/boiler-maintenance.module').then((m) => m.BoilerMaintenanceModule),
            },
            {
                path: '**',
                component: RedirectComponent,
                pathMatch: 'full',
            },
        ],
    },
    {
        path: 'header',
        outlet: modalRouterOutlet,
        component: ExternalInfluencerConfigureHeaderComponent,
    },
    {
        path: 'about',
        outlet: modalRouterOutlet,
        component: ExternalInfluencerConfigureAboutComponent,
    },
    {
        path: 'locations',
        outlet: modalRouterOutlet,
        component: ExternalInfluencerConfigureLocationsComponent,
    },
    {
        path: 'contact',
        outlet: modalRouterOutlet,
        component: ExternalInfluencerConfigureContactComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: AppPreLoadingStrategy })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
