import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationData, RxjsComponent } from '@smooved/core';
import { filter, takeUntil } from 'rxjs/operators';
import { ActivityLogSandbox } from './activity-log/sandboxes/activity-log.sandbox';
import { AuthenticationSandbox } from './authentication/sandboxes/authentication.sandbox';
import { CoBrandingSandbox } from './co-branding/sandboxes/co-branding.sandbox';
import { AppNavigationSandbox } from './navigation/sandboxes/navigation.sandbox';
import { RealEstateGroupSandbox } from './real-estate-group/sandboxes/real-estate-group.sandbox';
import { AppUiSandbox } from './ui/sandboxes/ui.sandbox';
import { VersionCheckService } from './version-check.service';
import { DOCUMENT } from '@angular/common';
import { uiV1 } from '@app/app.constants';

@Component({
    selector: 'app-root',
    template: `
        <app-loading-overlay *ngIf="uiSandbox.loadingOverlay$ | async"></app-loading-overlay>

        <router-outlet></router-outlet>

        <app-activity-log-heartbeat *ngIf="authenticationSandbox.isRealEstateAgent$ | async"></app-activity-log-heartbeat>
        <app-stop-impersonate *ngIf="!!(authenticationSandbox.isImpersonatedRealEstateAgent$ | async)"></app-stop-impersonate>
        <app-beamer-plugin></app-beamer-plugin>
    `,
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends RxjsComponent implements OnInit {
    isMobile: boolean;

    constructor(
        public readonly uiSandbox: AppUiSandbox,
        public readonly authenticationSandbox: AuthenticationSandbox,
        private readonly coBrandingSandbox: CoBrandingSandbox,
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly navigationSandbox: AppNavigationSandbox,
        private readonly activityLogSandbox: ActivityLogSandbox,
        private readonly versionCheckService: VersionCheckService,
        private readonly router: Router,
        @Inject(DOCUMENT) private document: Document
    ) {
        super();
    }

    public ngOnInit(): void {
        this.hasCoBranding();
        this.trackPageViews();
        this.versionCheckService.initVersionCheck();
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.isMobile = this.checkIfMobileRoute(event.url);
        });
        this.setStyleContextOnRouteChange();
    }

    /**
     * This method will add the 'deprecated' ui-v1 class to the HTML tag when no other version is defined in the route config (eg: leaver dashboard v2)
     * @private
     */
    private setStyleContextOnRouteChange(): void {
        this.navigationSandbox.routeNavigationEndData$.subscribe((data) => {
            if (!data.styleVersion) {
                this.document.documentElement.classList.add(uiV1);
            } else {
                this.document.documentElement.classList.remove(uiV1);
            }
        });
    }

    private trackPageViews(): void {
        this.navigationSandbox.routeNavigationEndData$
            .pipe(
                filter((data) => data?.[NavigationData.TrackPageView]),
                takeUntil(this.destroy$)
            )
            .subscribe((_) => this.activityLogSandbox.trackPageView());
    }

    private hasCoBranding(): void {
        this.coBrandingSandbox.hasCoBranding$.pipe(takeUntil(this.destroy$)).subscribe((hasCoBranding) => {
            if (hasCoBranding) {
                this.realEstateGroupSandbox.themeOnce$.subscribe((theme) => {
                    this.realEstateGroupSandbox.setThemeStyling(theme);
                });
            } else {
                this.realEstateGroupSandbox.unsetThemeStyling();
            }
        });
    }

    checkIfMobileRoute(url: string): boolean {
        return url.startsWith('/mobile');
    }
}
