import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { noErrorNotificationHeader } from '@app/http/interceptors/http-interceptor.constants';
import { HttpUtils, ObjectUtils } from '@core/utils';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType } from '@ui-v2/molecules/toast/toast.constants';
import { ToastService } from '@ui-v2/molecules/toast/toast.service';
import { catchError, Observable, throwError } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { uiV2 } from '@app/app.constants';

export class HandleHttpErrorInterceptor implements HttpInterceptor {
    private toastService = inject(ToastService);
    private translateService = inject(TranslateService);
    private activatedRoute = inject(ActivatedRoute);

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        /** This will prevent to use old notification system if error from http call */
        const headers = { [noErrorNotificationHeader]: 'true' };
        const confirmationRequest = HttpUtils.setHeaders(request, headers);

        return next.handle(confirmationRequest).pipe(
            catchError((errorResponse: HttpErrorResponse): Observable<HttpEvent<any>> => {
                if (!this.isUiV2()) return throwError(() => errorResponse);
                const { error } = errorResponse;
                const translation = this.translateService.instant((error.errorCode as string) || error.error || 'ERROR');
                const notification = ObjectUtils.isObject(translation)
                    ? { message: translation['LABEL']?.(), title: translation['TITLE']?.() }
                    : { message: translation };
                this.toastService.showNotification({ ...notification, type: NotificationType.Error });
                return throwError(() => error);
            })
        );
    }

    private isUiV2(): boolean {
        return this.activatedRoute.root.snapshot.firstChild?.data?.styleVersion === uiV2;
    }
}
