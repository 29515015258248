@import '../../../projects/ui/src/lib/scss/themes/smooved/variables';
@import '../../../projects/ui/src/lib/scss/base/mixins';

$transition: all ease 0.2s;

app-main-container-template {
    display: flex;
    flex-grow: 1;
    height: 100%;
    width: 100%;

    app-header-container {
        position: fixed;
        width: 100%;

        @include up-to-and-including-phone-landscape {
            min-height: $header-height;
        }

        @include tablet-portrait-up {
            min-height: $header-height-tablet-portrait-up;
        }
    }

    .main-container-template-body {
        transition: $transition;
        width: 100%;

        @include up-to-and-including-phone-landscape {
            margin-top: $header-height;
        }

        @include tablet-portrait-up {
            margin-top: $header-height-tablet-portrait-up;
        }
    }

    app-sidebar {
        overflow-x: hidden;
        transition: $transition;
        bottom: 0;
        left: 0;

        @include up-to-and-including-phone-landscape {
            top: $header-height;
        }

        @include tablet-portrait-up {
            top: $header-height-tablet-portrait-up;
        }

        position: fixed;
        z-index: 999;

        &:not(.__menu) {
            width: $sidebar-width-open;
        }

        &:not([hidden]).__menu ~ .main-container-template-body {
            margin-left: $sidebar-main-menu-width;
        }

        &:not([hidden]):not(.__menu) ~ .main-container-template-body {
            margin-left: calc(#{$sidebar-width-open} + #{$sidebar-main-menu-width});
        }

        &:not([hidden]) + app-sidebar:not([hidden]) + .main-container-template-body {
            margin-left: calc(2 * #{$sidebar-width-open} + #{$sidebar-main-menu-width});
        }

        &:not([hidden]):not(.__menu) + app-sidebar:not([hidden]) + .main-container-template-body {
            margin-left: calc(2 * #{$sidebar-width-open} + #{$sidebar-main-menu-width});
        }

        &.__menu + app-sidebar {
            left: $sidebar-main-menu-width;
        }

        &:not(.__menu) + app-sidebar {
            left: calc(#{$sidebar-width-open} + #{$sidebar-main-menu-width});
        }

        & > .__collapse-toggle {
            right: var(--spacer-xs);
            top: var(--spacer-sm);
        }

        &.collapsed,
        &.collapsing {
            &:not(.collapsed--open) {
                width: $sidebar-width-closed;

                & + app-sidebar {
                    left: calc(#{$sidebar-width-closed} + #{$sidebar-main-menu-width});
                }
            }

            &:not([hidden]) ~ .main-container-template-body {
                margin-left: calc(#{$sidebar-width-closed} + #{$sidebar-main-menu-width});
            }

            &:not([hidden]) + app-sidebar:not([hidden]) + .main-container-template-body {
                margin-left: calc(#{$sidebar-width-closed} + #{$sidebar-width-open} + #{$sidebar-main-menu-width});
            }

            & > .__collapse-toggle {
                transition: $transition;
            }
        }

        &.__menu {
            background-color: var(--color-white);
            width: $sidebar-main-menu-width;

            & > .__menu-container {
                :host-context(.__menu-item-label) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                // This css is been included because otherwise we would need to change the component, and it would bring problems
                // for other parent components that are using this
                .__menu-item {
                    .__menu-item-label,
                    app-svg-illustration {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        color: var(--color-gray-dark);
                    }

                    &.is-active,
                    &.is-active:hover {
                        .__menu-item-label,
                        app-svg-illustration {
                            color: inherit;
                        }
                    }

                    &:hover {
                        .__menu-item-label,
                        app-svg-illustration {
                            color: inherit;
                        }
                    }
                }

                div {
                    & > a {
                        margin: 0px;
                    }
                }

                .__menu-item-button .__menu-item.is-active:not(.__menu-item--disabled):after,
                .__menu-item.__menu-item-button .__menu-item.is-active:not(.__menu-item--disabled):after,
                .__menu-item-button .__menu-item:hover:not(.__menu-item--disabled):after {
                    left: 0;
                    right: 0;
                }
            }
        }

        .__menu-container:not(:last-child),
        app-menu-item:not(:last-child) {
            margin-bottom: 0.5rem;
        }

        .__menu-group {
            app-menu-item .__menu-item {
                padding: 0.75rem 0 !important;
            }
        }
    }
}
