app-header-menu-mobile {
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-trigger.mat-select-trigger {
        min-height: 54px;
        line-height: 54px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-value-text {
        font-weight: 500;
    }

    .__collapse-toggle {
        margin: 1rem .5rem 0;
        text-decoration: none;
    }

    app-menu-item {
        &:not(:last-of-type):not(.__menu-item-link) .__menu-item {
            border-bottom: none !important;
        }

        .__menu-item {
            padding-top: .75rem !important;
            padding-bottom: .75rem !important;
            margin-top: .25rem !important;
            margin-bottom: .25rem !important;
        }
    }

    app-reviews-menu, app-dashboard-menu, app-metrics-menu, app-management-menu {
        padding-top: 0 !important;
    }

    app-dashboard-menu {
        padding-right: 1.5rem !important;

        .__menu-button.__menu-button {
            margin-left: 0;
        }
    }
}
