import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { AuthUtils } from '@app/authentication/auth.utils';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { FEATURE_TOGGLE_CONFIG, FeatureToggle } from '@core/feature-toggle';
import { combineLatest, map, Observable } from 'rxjs';

export function DashboardV2AllowedRolesGuard(): CanActivateFn {
    return (): Observable<true | UrlTree> => {
        const featureToggleConfig = inject(FEATURE_TOGGLE_CONFIG);
        const authenticationSandbox = inject(AuthenticationSandbox);
        const moveSandbox = inject(MoveSandbox);
        const router = inject(Router);

        return combineLatest([authenticationSandbox.authorization$, moveSandbox.move$]).pipe(
            map(([authUser, move]) => {
                if (AuthUtils.isMover(authUser) && MoveUtils.isLeaver(move) && featureToggleConfig[FeatureToggle.LeaverDashboard])
                    return true;
                if (AuthUtils.isRealEstateAgent(authUser)) return router.createUrlTree(['/', Navigation.RealEstateAgent]);
                return router.createUrlTree(['/', Navigation.Dashboard]);
            })
        );
    };
}
